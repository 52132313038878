import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useStore = create(
  persist(
    (set) => ({
      passwordAuthenticated: false,
      updatePasswordAuthenticated: (updatedStatus) =>
        set({ passwordAuthenticated: updatedStatus }),
      email: null,
      updateEmail: (emailInput) => set({ email: emailInput }),
    }),
    {
      name: "auth-status-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

// export const useStore = create((set) => ({
//   count: 2,
//   updateCount: (newCount) => set({ count: newCount }),
//   passwordAuthenticated: false,
//   updatePasswordAuthenticated: (updatedStatus) =>
//     set({ passwordAuthenticated: updatedStatus }),
//   email: null,
//   updateEmail: (emailInput) => set({ email: emailInput }),
// }));
