import { useStore } from "../../store/store";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

function Auth() {
  const passwordAuthenticated = useStore(
    (state) => state.passwordAuthenticated
  );
  return (
    <div className="bg-gray-900 w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col justify-start gap-4">
        <div className="text-white text-xl font-semibold text-left">
          {passwordAuthenticated ? "Step 2 of 2" : "Step 1 of 2"}
        </div>
        <div className="border-2 border-gray-50 rounded-lg w-[320px] h-[420px] flex flex-col justify-between self-center p-2">
          <img
            src="./logo-white.svg"
            height={40}
            width={230}
            alt="Flippr Logo"
            className="self-center pt-8"
          />
          {passwordAuthenticated ? <StepTwo /> : <StepOne />}
        </div>
      </div>
    </div>
  );
}

export default Auth;
