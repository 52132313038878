import { useEffect, useState } from "react";
import Auth from "./components/auth/Auth";
import SideNav from "./components/SideNav";
import SlideContent from "./components/SlideContent";
import { useStore } from "./store/store";

function App() {
  const passwordAuthenticated = useStore(
    (state) => state.passwordAuthenticated
  );
  const email = useStore((state) => state.email);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    // document.querySelector("body").scrollTo(0, 0);
  }, [currentSlide]);
  return (
    <div className="flex">
      {passwordAuthenticated && email ? (
        <>
          <SideNav
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
          <SlideContent
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        </>
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default App;
