import { useEffect, useRef } from "react";

function SlideTemplate({ title1, title2, sections }) {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTo(0, 0);
  }, []);
  return (
    <div className="mt-[16px] mb-[24px] w-full h-[28px]" ref={ref}>
      <span className="uppercase text-gray-600 font-bold w-full">{title1}</span>
      <div className="">
        <span className="text-3xl font-bold">{title2}</span>
      </div>
      <div className="mt-[32px]">
        {sections.map((section) => (
          <div key={section.id} className="mt-4">
            <span className="text-2xl font-bold">{section.title}</span>
            <div className="py-2">{section.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SlideTemplate;
