import TableOfContents from "./TableOfContents";
import { useState, useEffect } from "react";

function SideNav({ currentSlide, setCurrentSlide }) {
  const [pageInput, setPageInput] = useState(currentSlide);
  useEffect(() => {
    setPageInput(currentSlide);
  }, [currentSlide]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      switch (true) {
        case isNaN(pageInput):
          setCurrentSlide(1);
          setPageInput(1);
          break;
        case pageInput > 11:
          setPageInput(11);
          setCurrentSlide(11);
          break;
        case pageInput < 1:
          setPageInput(1);
          setCurrentSlide(1);
          break;
        default:
          setCurrentSlide(Number(pageInput));
          break;
      }
    }
  };

  const handleBlur = () => {
    switch (true) {
      case isNaN(pageInput):
        setCurrentSlide(1);
        setPageInput(1);
        break;
      case pageInput > 11:
        setPageInput(11);
        setCurrentSlide(11);
        break;
      case pageInput < 1:
        setPageInput(1);
        setCurrentSlide(1);
        break;
      default:
        setCurrentSlide(Number(pageInput));
        break;
    }
  };

  return (
    <div>
      <div className="hidden fixed lg:flex flex-wrap flex-col justify-between h-screen overflow-hidden w-[296px] bg-[#0e131d] py-[20px]">
        <div className="w-auto flex flex-wrap flex-col items-center">
          <img src="/logo-white.svg" width={215} alt="FLIPPR" />
        </div>
        <div className="mx-[auto] w-[200px] text-center">
          <h1 className="text-[22px] text-gray-50">
            Long-term Vision<br></br>
            &#40;Summer 2023&#41;
          </h1>
        </div>
        <div className="flex flex-row flex-wrap justify-center items-center w-auto mx-auto px-[16px] py-2 border border-gray-600 rounded-lg ">
          <p className="flex flex-row flex-wrap justify-center items-center w-auto leading-7 text-gray-100 text-[14px]">
            page
            <input
              typ="text"
              name="page-input"
              id="page-input"
              min="1"
              max="20"
              value={pageInput}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) => setPageInput(e.target.value)}
              className="bg-gray-700 flex flex-wrap flex-row items-center justify-center leading-7 w-[20px] mx-2 text-gray-50 text-center placeholder:text-gray-50"
            />
            / <span className="ml-2">11</span>
          </p>
        </div>
        <div>
          <TableOfContents
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        </div>
      </div>

      {/* <nav class="bg-gray-900 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
        <div class="container flex flex-wrap items-center justify-between mx-auto">
          <a href="https://flowbite.com/" class="flex items-center">
            <img
              src="https://flowbite.com/docs/images/logo.svg"
              class="h-6 mr-3 sm:h-9"
              alt="Flowbite Logo"
            />
            <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Flowbite
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="#"
                  class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      <div className="tablet-nav fixed flex md:hidden flex-wrap flex-row items-center justify-between h-[40px] w-full bg-[#0e131d] !z-[100]">
        <div className="ham-and-text-phone ml-[32px] flex flex-wrap flex-row items-center justify-left">
          <input type="checkbox" id="side-menu" className="side-menu" />
          <label
            for="side-menu"
            className="hamb w-[32px] h-[32px] z-51 flex flex-wrap flex-row items-center justify-center p-[8px] hover:bg-gray-600 hover:rounded-[100%]"
          >
            <div className="toc-wrap fixed top-[40px]">
              <TableOfContents
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            </div>
            <span className="hamb-line"></span>
          </label>
          <div className="flex flex-wrap flex-col items-center justify-center">
            <h1 className="text-[14px] text-gray-50 flex flex-wrap flex-col items-center justify-center">
              Flippr Long-term Vision
            </h1>
          </div>
        </div>
        <div className="tablet-page-number-wrap fixed left-0 right-0 h-[40px] flex flex-row flex-wrap justify-center items-center w-[100px] mx-auto px-[8px]">
          <p className="flex flex-row flex-wrap justify-center items-center w-auto leading-5 text-gray-100 text-[12px]">
            <input
              typ="number"
              name="page-input2"
              id="page-input2"
              min="1"
              max="20"
              value={pageInput}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) => setPageInput(e.target.value)}
              className="bg-gray-700 flex flex-wrap flex-row items-center justify-center leading-5 w-[16px] mx-2 text-gray-50 text-center placeholder:text-gray-50"
            />
            / <span className="ml-2">11</span>
          </p>
        </div>
        <div className="tablet-flippr-image w-auto mr-[40px] flex flex-wrap flex-col items-center justify-center">
          <img src="/logo-white.svg" width={80} alt="FLIPPR" />
        </div>
      </div>
    </div>
  );
}

export default SideNav;
