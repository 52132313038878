import SlideTemplate from "../SlideTemplate";

function Disclaimer() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="disclaimer" title2={[ ]} sections={[ ]} />
      <p className="text-gray-900 text-[16px] leading-[24px]">
        This presentation &#40;together with any accompanying oral comments or discussion, this “Presentation”&#41; has been provided to you by Plz Fix Inc. 
        and its affiliates &#40;collectively “Plz Fix”&#41; and may not be used or relied upon for any purpose without the written consent of Plz Fix. 
        No information contained in or referred to in this Presentation shall be construed to be a representation, warranty or undertaking from Plz Fix 
        &#40;express or implied&#41;. The information set out in this Presentation is subject to material updating, revision, correction, completion and 
        amendment from time to time, and Plz Fix expressly disclaims any and all liability for statements or information contained in, or for any errors 
        in or omissions from, this Presentation. This Presentation may include certain statements, estimates and projections with respect to anticipated 
        future performance. Any such statements, estimates and projections reflect various assumptions by Plz Fix concerning anticipated results, which 
        assumptions may or may not prove to be correct. There can be no assurance that such projections and statements will be realized, and the actual 
        results may vary from the anticipated results and such variations may be material.
      </p>
      <div className="divider16"></div>
      <p className="text-gray-900 text-[16px] leading-[24px]">
        The information contained herein &#40;the “Information”&#41; is confidential information. By accepting this Information, you agree that you will, 
        and you will cause your directors, partners, officers, employees, attorney&#40;s&#41;, agents and representatives to, use the Information only for 
        your informational purposes and for no other purpose and will not divulge any such Information to any other party. Any reproduction of this 
        Information, in whole or in part, is prohibited. These contents are proprietary information and products of Plz Fix. 
      </p>
      <div className="divider16"></div>
      <p className="text-gray-900 text-[16px] leading-[24px]">
        This Presentation shall not constitute an offer to sell, invitation to purchase, or other solicitation of purchase offers for, any securities or 
        any assets, business or undertakings described herein. This Presentation shall not form the basis of any contract, and the provision of this 
        Presentation should not be taken as any form of commitment on the part of Plz Fix to proceed with any negotiations or any transaction, and Plz Fix 
        reserves the right to discontinue discussions or negotiations regarding any transaction at any time for any reason or no reason. Any decision to 
        invest in the investments described herein should be made after conducting investigations as you deem necessary and consulting investment, legal, 
        accounting and tax advisors in order to make an independent determination of the suitability and consequences of an investment. Nothing contained 
        herein should be construed as tax, accounting or legal advice. 
      </p>            
      <div className="divider24"></div>
    </div>
  );
}

export default Disclaimer;
