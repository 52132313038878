import SlideTemplate from "../SlideTemplate";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Solution() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate
        title1="solution"
        title2={[ ]}
        sections={[ ]}
      />
      <h2>Create a presentation like this one in minutes with <span className="text-primary-700">Flippr.co</span></h2>
      <div className="photo-3 flex justify-center max-w-[600px] mx-auto">
        <img
          src="/Flippr.co-Designer-Comp.png"
          width="100%"
          height="auto"
          alt="Flippr.co mockup"
        />
      </div>
      <h2><span className="text-primary-700">Flippr.co</span> reimagines the slide deck to create the next-gen platform for business and professional story-telling</h2>      
      <div className="divider32"></div>
      <div className="callout-box stay-left">
        <div className="circle-check-wrap">                    
            <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />          
        </div>        
          <p className="paragraph-big text-gray-900 stay-left">Speed up presentation development time</p>              
      </div>
      <div className="callout-box stay-left">
        <div className="circle-check-wrap">          
          <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />
        </div>        
          <p className="paragraph-big text-gray-900 stay-left">Create more dynamic and organized presentations</p>              
      </div>
      <div className="callout-box stay-left">
        <div className="circle-check-wrap">          
          <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />
        </div>        
          <p className="paragraph-big text-gray-900 stay-left">Better integrate data sources</p>
      </div>
      <div className="callout-box stay-left">
        <div className="circle-check-wrap">          
          <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />
        </div>        
          <p className="paragraph-big text-gray-900 stay-left">Work collaboratively with your team</p>
      </div>
      <div className="divider16"></div>
    </div>
  );
}

export default Solution;
