import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/Styles.css";

const slides = [
  {
    id: 1,
    name: "1. Problem",
  },
  {
    id: 2,
    name: "2. Solution",
  },
  {
    id: 3,
    name: "3. Team",
  },
  {
    id: 4,
    name: "4. Why now?",
  },
  {
    id: 5,
    name: "5. Competition",
  },
  {
    id: 6,
    name: "6. Wedge",
  },
  {
    id: 7,
    name: "7. Market size",
  },  
  {
    id: 8,
    name: "8. Business model",
  },
  {
    id: 9,
    name: "9. Traction",
  },  
  {
    id: 10,
    name: "10. Timeline",
  },
  {
    id: 11,
    name: "Disclaimer",
  },
];

function TableOfContents({ currentSlide, setCurrentSlide }) {
  return (
    <div className="w-[296px]">
      <div className="toc-box mx-[24px] px-2 bg-[#1f2835] text-gray-200 rounded-lg">
        <div className="py-2 border-b-1">
          <span className="uppercase font-semibold">table of contents</span>
        </div>
        <hr />
        {slides.map((slide) => (
          <div
            key={slide.id}
            onClick={() => setCurrentSlide(slide.id)}
            className={`toc-text cursor-pointer py-[6px] normal-case border-b hover:bg-gray-600  ${
              currentSlide === slide.id
                ? "text-gray-50 font-semibold toc-text-active tracking-wide"
                : "text-gray-100 tracking-wide"
            }`}
          >
            <span className="ml-2">{slide.name}</span>
            <span className={"pl-2 text-gray-800 hidden"}><FontAwesomeIcon icon={faArrowLeft} /></span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TableOfContents;
