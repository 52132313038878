import SlideTemplate from "../SlideTemplate";

function Wedge() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="wedge" title2={[]} sections={[]} />
      <div className="flex flex-row">
        <div className="mobile-hidden">
          <img
            className="mr-[8px] mt-[-2px] min-w-[32px] max-w-[32px]"
            src="/Mouse-Wedge.png"
            width="100%"
            height="auto"
            alt="Wedge of cheese"
          ></img>
        </div>
        <h2 className="">
          Flippr&#39;s first step
          was Flippr.<span className="text-primary-700">ai</span>
        </h2>
      </div>
      <div className="divider8"></div>

      <p className="paragraph-big text-left-important">
        Flippr offers{" "}
        <a
          href="https://www.flippr.ai/"
          className="text-primary-700 hover:underline"
        >
          Flippr.ai
        </a>{" "}
        to help with the review and iteration process for customers using legacy
        presentation software and hopes to convert these users to its more
        innovative platform, Flippr.co, over time
      </p>
      <div className="divider40"></div>

      <div className="mobile-no-shadow shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[8px] ">
        <div className="mobile-no-shadow mobile-col flex flex-row flex-wrap justify-between py-[16px] px-[24px] items-start">
          <h2 className="w-[55%] mobile-col">
            <a
              href="https://www.flippr.ai/"
              className="text-primary-700 hover:underline"
            >
              Flippr.ai{" "}
            </a>
            creates &#8220;FlipLines&#8221; for slide decks, similar to
            redlines for word docs
          </h2>
          <div className="photo-4 photo-redline flex justify-center max-w-[250px] min-w-[175px]">
            <img
              src="/Redline.png"
              width="100%"
              height="auto"
              alt="Word doc redline"
            />
          </div>
        </div>
      </div>
      <div className="divider40"></div>

      <p className="paragraph-big text-left-important mobile-col">
        It is a collaborative web-app with tools to track changes, create
        markups, share documents and more
      </p>
      <div className="divider40"></div>

      <button
        type="button"
        className="transition-all w-fit bg-primary-700 text-gray-50 text-[18px] font-medium rounded-[6px] hover:bg-primary-800 hover:mt-[-2px] hover:mb-[2px] tracking-wider py-[12px] px-[24px] flex flex-row justify-center flex-nowrap"
      >
        <a href="https://flippr.ai/" className="w-full">
          Try it for free
        </a>
      </button>
      <div className="divider40"></div>
      <p className="paragraph-big text-left-important mobile-col">
        To sign up for a Pro Plan, use <a href="https://app.flippr.ai/" className="text-primary-700 hover:underline">plzfix2023</a> as the password and discount code 
      </p>
      <div className="divider32"></div>
    </div>
  );
}

export default Wedge;
