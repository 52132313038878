import { useEffect, useRef } from "react";
import SlideTemplate from "../SlideTemplate";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function WhyNow() {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTo(0, 0);
  }, []);
  return (
    <div className="inner-slide-content overflow-visible pb-20" ref={ref}>
      <SlideTemplate title1="why now?" title2={[]} sections={[]} />
      <div className="mobile-col flex flex-row flex-wrap items-center">
        <h2 className="w-1/2 mobile-col">
          Post-covid, the world is ready for a digital-first presentation
          platform
        </h2>
        <div className="photo-4 flex justify-center max-w-[50%] min-w-[250px] mx-auto mt-[-8px] rounded-tr-[24px]">
          <img
            className="rounded-tr-[24px]"
            src="/paper-garbage.png"
            width="100%"
            height="auto"
            alt="person at computer throwing away paper"
          />
        </div>
      </div>
      <div className="divider24"></div>
      
      <div className="callout-box shrink-callout-box-mobile">
        <div className="circle-number-wrap mobile-hidden">          
          <p className="circle-number">1</p>
        </div>
        <div className="photo-and-image">        
          <div className="photo-2 flex justify-center max-w-[150px]">
            <img
              src="/Why-now/video-meeting.png"
              width="100%"
              height="auto"
              alt="Virtual meeting"
            />        
          </div>
          <p className="paragraph-big text-gray-900">Most business meetings are now virtual instead of in-person</p>      
        </div>
      </div>

      <div className="callout-box shrink-callout-box-mobile">
        <div className="circle-number-wrap mobile-hidden">          
          <p className="circle-number">2</p>
        </div>
        <div className="photo-and-image">        
          <div className="photo-2 flex justify-center max-w-[150px]">
            <img
              src="/Why-now/office-meeting-digital.png"
              width="100%"
              height="auto"
              alt="Virtual meeting"
            />        
          </div>
          <p className="paragraph-big text-gray-900">Notebooks and printouts are increasingly rare at in-person meetings, in favor of tablets, computers, and phones</p>      
        </div>
      </div>


      <div className="divider40"></div>
      <div className="divider40"></div>
      <h2>
        There is growing momentum for modernized web-apps displacing horizontal
        SaaS incumbents in adjacent sectors
      </h2>
      <div className="divider24"></div>
      <h3 className="">
        Figma and Canva created over $40 billion of value by making design tools
        easier to learn and use
      </h3>
      <div className="divider16"></div>
      <div className="chart-content-row flex flex-row justify-around gap-x-[40px] gap-y-[24px] min-h-fit">
        {/* this whole block is a chart in css with tooltips and logos */}
        <div class="chart-wrap">
          <div class="chart-column-wrap">
            <div class="chart-column-stack">
              <p class="chart-label-text">$1.1B</p>
              <div class="column1-stack1 hover-opacity">
                <div class="tooltip-wrap1 tooltip-hover">
                  <div class="tooltip">
                    <div class="paragraph-tooltip tooltip-text">
                      <span class="text-span-2">Canva</span>
                      <br></br>$40M Series A2 Jan 2018 <br></br>$1B Val
                    </div>
                    <div class="tooltip-arrow"></div>
                  </div>
                </div>
              </div>
              <div class="column1-stack2 hover-opacity"></div>
            </div>
            <div class="chart-column-stack">
              <p class="chart-label-text">$45B</p>
              <div class="column2-stack1 hover-opacity">
                <div class="tooltip-wrap2 tooltip-hover">
                  <div class="tooltip">
                    <div class="paragraph-tooltip tooltip-text">
                      <span class="text-span-2">Canva</span>
                      <br></br>&#126;$25B rumored investor valuation in Aug 2022
                    </div>
                    <div class="tooltip-arrow"></div>
                  </div>
                </div>
              </div>
              <div class="column2-stack2 hover-opacity">
                <div class="tooltip-wrap3 tooltip-hover">
                  <div class="tooltip">
                    <div class="paragraph-tooltip tooltip-text">
                      <span class="text-span-2">Figma</span>
                      <br></br>$20B acquisition by Adobe announced Sep 2022
                    </div>
                    <div class="tooltip-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-x-axis"></div>
          <div class="chart-column-wrap2">
            <div class="chart-column-stack">
              <p class="chart-axis-text">
                Valuation<br></br>5 Yrs Ago
              </p>
            </div>
            <div class="chart-column-stack">
              <p class="chart-axis-text">
                Valuation<br></br>Today
              </p>
            </div>
          </div>
          <div class="logo-column">
            <div class="logo-in-chart-wrap ml-[6px] mt-[12px]">
              <img
                src="/Logos/Logo-Canva.png"
                width="100%"
                height="auto"
                alt="Canva logo"
              />
            </div>
            <div class="logo-in-chart-spacer"></div>
            <div class="logo-in-chart-wrap">
              <img
                src="/Logos/Logo-Figma.png"
                width="100%"
                height="auto"
                alt="Figma logo"
              />
            </div>
          </div>
        </div>

        <div className="callout-box rounded-callout2 left-[0px]">
          <p className="absolute !font-medium paragraph-big text-primary-700 left-[28%]">
            Incumbents
          </p>
          <div className="flex flex-row flex-wrap justify-around items-center w-full max-w-[400px] max-h-[200px] p-[16px] mt-[32px] gap-[8px]">
            <div className="w-[120px]">
              <img
                src="/Logos/Logo-Adobe-Express.png"
                width="100%"
                height="auto"
                alt="Adobe Express"
              />
            </div>
            <div className="w-[100px]">
              <img
                src="/Logos/Logo-Adobe-Indesign.png"
                width="100%"
                height="auto"
                alt="Adobe Indesign"
              />{" "}
            </div>
            <div className="w-[110px]">
              <img
                src="/Logos/Logo-Adobe-Photoshop.png"
                width="100%"
                height="auto"
                alt="Adobe Photoshop"
              />{" "}
            </div>
            <div className="w-[40px]">
              <img
                src="/Logos/Logo-Adobe-Xd.png"
                width="100%"
                height="auto"
                alt="Adobe Xd"
              />{" "}
            </div>
            <div className="w-[44px]">
              <img
                src="/Logos/Logo-Publisher.png"
                width="100%"
                height="auto"
                alt="Microsoft Publisher"
              />{" "}
            </div>
          </div>
        </div>
      </div>

      {/* chart-content section 2 */}
      <div className="divider40"></div>
      <div className="divider40"></div>
      <h3 className="">
        All-in-one workspaces displaced horizontal enterprise tools by making
        project/doc management easier-to-use with visually organized platforms
      </h3>
      <div className="divider16"></div>
      <div className="chart-content-row flex flex-row justify-around items-center gap-x-[0px] min-h-fit pr-[24px]">
        <div className="callout-box rounded-callout2 neg-margin-left-why-now !rounded-[24px] !px-[2px] !pb-[0px]">
          <p className="absolute !font-medium paragraph-big text-primary-700 text-center left-[26%] max-w-[120px]">
            All-in-one Universe
          </p>
          <div className="flex flex-row flex-wrap justify-around items-center w-full max-w-[400px] max-h-[200px] p-[24px] mt-[40px] gap-y-[0px] gap-x-[8px]">
            <div className="w-[90px] overflow-hidden h-[36px]">
              <img
                src="/Logos/Logo-Airtable.png"
                width="100%"
                height="auto"
                alt="Airtable"
              />{" "}
            </div>
            <div className="w-[80px]">
              <img
                src="/Logos/Logo-ClickUp.png"
                width="100%"
                height="auto"
                alt="ClickUp"
              />
            </div>
            <div className="w-[60px]">
              <img
                src="/Logos/Logo-Coda.png"
                width="100%"
                height="auto"
                alt="Coda"
              />{" "}
            </div>
            <div className="w-[80px]">
              <img
                src="/Logos/Logo-Notion.png"
                width="100%"
                height="auto"
                alt="Notion"
              />{" "}
            </div>
          </div>
        </div>

        {/* this whole block is a chart in css with tooltips and logos */}
        <div class="chart-wrap">
          <div class="chart-column-wrap">
            <div class="chart-column-stack">
              <p class="chart-label-text">$500M</p>
              <div class="column1-stack1 hover-opacity !bg-primary-700">
                <div class="tooltip-wrap4 tooltip-hover">
                  <div class="tooltip">
                    <div class="paragraph-tooltip tooltip-text">
                      &#126;$500M aggregate value of all-in-one workspace
                      universe H1 2018
                    </div>
                    <div class="tooltip-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chart-column-stack">
              <p class="chart-label-text">$30B</p>
              <div class="column2-stack1 hover-opacity !bg-primary-700">
                <div class="tooltip-wrap5 tooltip-hover">
                  <div class="tooltip">
                    <div class="paragraph-tooltip tooltip-text">
                      &#126;$30B aggregate value of all-in-one workspace
                      universe H1 2023
                    </div>
                    <div class="tooltip-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-x-axis"></div>
          <div class="chart-column-wrap2">
            <div class="chart-column-stack">
              <p class="chart-axis-text">
                Valuation<br></br>5 Yrs Ago
              </p>
            </div>
            <div class="chart-column-stack">
              <p class="chart-axis-text">
                Valuation<br></br>Today
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="callout-box rounded-callout2 !mx-auto left-[0px] phone-max-width">
        <p className="absolute !font-medium paragraph-big text-primary-700 left-[29%]">
          Incumbents
        </p>
        <div className="flex flex-row flex-wrap justify-around items-center w-full max-w-[400px] max-h-[200px] p-[16px] mt-[16px] gap-x-[8px] gap-y-[0px]">
          <div className="w-[90px] mb-[-6px]">
            <img
              src="/Logos/Logo-Office.png"
              width="100%"
              height="auto"
              alt="Microsoft Office"
            />
          </div>
          <div className="w-[85px]">
            <img
              src="/Logos/Logo-G-Workspace.png"
              width="100%"
              height="auto"
              alt="Google Workspace"
            />{" "}
          </div>
          <div className="w-[90px]">
            <img
              src="/Logos/Logo-Atlassian.png"
              width="100%"
              height="auto"
              alt="Atlassian"
            />{" "}
          </div>
          <div className="w-[60px]">
            <img
              src="/Logos/Logo-Asana.png"
              width="100%"
              height="auto"
              alt="Asana"
            />{" "}
          </div>
        </div>
      </div>

      <div className="divider40"></div>
      <div className="divider40"></div>
      <h2 className="">
        The PowerPoint alternatives landscape is nascent and fragmented, with an
        aggregate valuation of $1-2B and several recent seed rounds
      </h2>
      <div className="divider16"></div>
      <div className="!mx-auto pr-[2px]">
        <div className="callout-box rounded-callout3 why-now-neg-left !min-w-[450px] max-w-[450px] !min-h-[250px] h-fit grow !rounded-[24px]">
          <p className="absolute !font-medium paragraph-big text-primary-700 !w-[100%] justify-center !text-center ml-[-12px]">
            Selected Universe
            <span className="!no-underline">
              <sup>1</sup>
            </span>
          </p>
          <div className="bigger-logo-box-phone flex flex-row flex-wrap justify-around items-center w-full max-w-[400px] max-h-[200px] p-[16px] mt-[32px] gap-[8px]">
            <div className="w-[60px]">
              <img
                src="/Logos/Logo-P-Pitch.png"
                width="100%"
                height="auto"
                alt="Pitch"
              />
            </div>
            <div className="w-[65px]">
              <img
                src="/Logos/Logo-P-Prezi.png"
                width="100%"
                height="auto"
                alt="Prezi"
              />{" "}
            </div>
            <div className="w-[75px]">
              <img
                src="/Logos/Logo-P-Templafy.png"
                width="100%"
                height="auto"
                alt="Templafy"
              />{" "}
            </div>
            <div className="w-[70px]">
              <img
                src="/Logos/Logo-P-Beaut.png"
                width="100%"
                height="auto"
                alt="Beautiful.ai"
              />{" "}
            </div>
            <div className="w-[60px]">
              <img
                src="/Logos/Logo-P-Visme.png"
                width="100%"
                height="auto"
                alt="Visme"
              />{" "}
            </div>
            <div className="w-[80px]">
              <img
                src="/Logos/Logo-P-Matik-Date.png"
                width="100%"
                height="auto"
                alt="Matik"
              />{" "}
            </div>
            <div className="w-[110px]">
              <img
                src="/Logos/Logo-P-Prezent-Date.png"
                width="100%"
                height="auto"
                alt="Prezent.ai"
              />{" "}
            </div>
            <div className="w-[70px]">
              <img
                src="/Logos/Logo-P-Tome-Date.png"
                width="100%"
                height="auto"
                alt="Tome"
              />{" "}
            </div>
            <div className="w-[70px]">
              <img
                src="/Logos/Logo-P-Foleon.png"
                width="100%"
                height="auto"
                alt="Foleon"
              />{" "}
            </div>
            <div className="w-[80px]">
              <img
                src="/Logos/Logo-P-Proposify.png"
                width="100%"
                height="auto"
                alt="Proposify"
              />{" "}
            </div>
            <div className="w-[80px]">
              <img
                src="/Logos/Logo-P-Gamma-Date.png"
                width="100%"
                height="auto"
                alt="Gamma"
              />{" "}
            </div>
            <div className="w-[75px]">
              <img
                src="/Logos/Logo-P-Slidebean.png"
                width="100%"
                height="auto"
                alt="Visme"
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <p className="!text-[14px] !leading-[22px] mx-auto max-w-[450px]">
        Note: &#40;1&#41; universe excludes broader design tools &#40;e.g.
        Canva, Figma&#41; as well as Google Slides, Keynote and print design
        tools &#40;e.g. inDesign&#41;
      </p>
      <div className="divider40"></div>
      <div className="divider40"></div>
      <h2 className="">
        Generative AI is a tailwind for Flippr.co
      </h2>
      <div className="callout-box why-now">
        <div className="circle-check-wrap">          
          <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />
        </div>        
        <p className="paragraph-big text-gray-900 stay-left">
            It is increasingly easy to implement into the application layer, 
            with obvious use cases for Flippr.co
        </p>
      </div>
      <div className="callout-box why-now">
        <div className="circle-check-wrap">          
          <FontAwesomeIcon icon={faCheck} className="h-6 circle-check" />
        </div>        
        <p className="paragraph-big text-gray-900 stay-left">
            It speeds up development time with coding automation, 
            lowering the barriers to compete with incumbent tech players
        </p>
      </div>

      <div className="divider40"></div>
    </div>
  );
}

export default WhyNow;
