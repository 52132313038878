import SlideTemplate from "../SlideTemplate";

function MarketSize() {
  return (
    <div className="inner-slide-content justify-center overflow-x-auto">
      <SlideTemplate title1="market size" title2={[]} sections={[]} />
      <h2 className="text-center">Market validation</h2>

      {/* 2 market size bubbles */}
      <div className="market-wrap-phone flex flex-wrap flex-row w-full justify-center gap-[32px]">
        <div className="shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[100%] px-[16px] py-[8px] w-[250px] sm:w-[330px] sm:h-[330px] min-h-[330px] relative">
          <div className="flex flex-row justify-center">
            <div className="max-w-[50px] absolute">
              <img
                src="/Logos/Logo-PPT.png"
                width="100%"
                height="auto"
                alt="PowerPoint Logo"
              />
            </div>
          </div>
          <div className="market-wrap-text-mobile flex flex-nowrap flex-col justify-center gap-[12px]">
            <div className="flex flex-nowrap flex-col justify-center mt-[42px]">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                1.2 billion
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                Microsoft Office<br></br>Users
              </p>
            </div>
            <div className="flex flex-nowrap flex-col justify-center">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                500 million
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                PPT Users
              </p>
            </div>
            <div className="flex flex-nowrap flex-col justify-center">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                120 million
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                Business / Education<br></br>PPT Users
              </p>
            </div>
          </div>
        </div>

        <div className="shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[100%] px-[16px] py-[8px] w-[250px] sm:w-[330px] sm:h-[330px] h-[330px] min-h-[330px] relative">
          <div className="flex flex-row justify-center">
            <div className="adobe-logo-wrap min-w-[50px] max-w-[100px] absolute mt-[12px]">
              <img
                src="/Logos/Logo-Adobe.png"
                width="100%"
                height="auto"
                alt="Adobe Logo"
              />
            </div>
          </div>
          <div className="market-wrap-text-mobile flex flex-nowrap flex-col justify-center gap-[12px]">
            <div className="flex flex-nowrap flex-col justify-center mt-[42px]">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                300 billion
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                PDFs Opened with<br></br>Adobe Products in 2021
              </p>
            </div>
            <div className="flex flex-nowrap flex-col justify-center">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                1 billion
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                Acrobat Users
              </p>
            </div>
            <div className="flex flex-nowrap flex-col justify-center">
              <p className="market-text1 text-primary-700 font-semibold text-[32px] leading-[40px] text-center">
                35 million
              </p>
              <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                Paid Acrobat <br></br>Subscribers
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="divider40"></div>
      <div className="divider40"></div>

      <h2 className="text-center">Total addressable market</h2>
      {/* TAM flow chart */}
      <div className="w-full max-w-[375px] mx-auto">
        <div className="flex flex-row items-top w-full">
          <div className="market-flow-line2"></div>
          <div className="flex flex-row items-center w-full">
            <div className="market-flow-line1"></div>
            <div className="rounded-[100%] bg-primary-700 min-w-[16px] w-[16px] h-[16px]"></div>
            <div className="flex flex-row justify-center shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[100%] px-[16px] py-[8px] w-[180px] h-[180px] min-h-[180px] relative">
              <div className="flex flex-row justify-center w-full absolute">
                <div className="max-w-[50px]">
                  <img
                    src="/Logos/Logo-PPT.png"
                    width="100%"
                    height="auto"
                    alt="PowerPoint Logo"
                  />
                </div>
              </div>
              <div className="market-wrap-text-mobile flex flex-nowrap flex-col justify-center gap-[12px]">
                <div className="divider8"></div>
                <div className="flex flex-nowrap flex-col justify-center">
                  <p className="market-text1 text-primary-700 font-semibold text-[28px] leading-[36px] text-center">
                    120 million
                  </p>
                  <p className="market-text2 text-gray-900 font-medium text-[20px] leading-[22px] text-center">
                    Business/Ed.<br></br>PPT Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary-700 w-[2px] h-[32px]"></div>
        <div className="flex flex-row items-top w-full">
          <div className="market-line-grow bg-primary-700 w-[2px] h-[160px]"></div>
          <div className="market-flow-line3"></div>
          <div className="triangle-market"></div>
          <div className="mt-[-8px] market-flow-callout-mobile flex flex-nowrap flex-col justify-center min-w-[230px] max-w-[230px] shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[8px] p-[8px]">
            <p className="text-gray-900 font-semibold text-[24px] leading-[30px] text-center mb-[8px]">
              Flippr.<span className="text-primary-700">co</span>
            </p>
            <p className="market-text2 text-gray-900 font-normal text-[18px] leading-[22px] text-center">
              For adopters of next-gen presentation software
            </p>
            <p className="market-text1 text-primary-700 font-semibold text-[24px] leading-[28px] mt-[8px] mb-[8px] text-center">
              X million
            </p>
          </div>
        </div>
        <div className="bg-primary-700 w-[2px] h-[40px]"></div>
        <div className="flex flex-row items-top w-full">
          <div className="market-line-grow2 bg-primary-700 w-[2px] h-[160px]"></div>
          <div className="market-flow-line3"></div>
          <div className="triangle-market"></div>
          <div className="mt-[-8px] market-flow-callout-mobile flex flex-nowrap flex-col justify-center min-w-[230px] max-w-[230px] shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[8px] p-[8px]">
            <p className="text-gray-900 font-semibold text-[24px] leading-[30px] text-center mb-[8px]">
              Flippr.<span className="text-primary-700">ai</span>
            </p>
            <p className="market-text2 text-gray-900 font-normal text-[18px] leading-[22px] text-center">
              For legacy slide-deck users interested in review/iteration tools
            </p>
            <p className="market-text1 text-primary-700 font-semibold text-[24px] leading-[28px] mt-[8px] mb-[8px] text-center">
              &#91;120 - X&#93; million
            </p>
          </div>
        </div>
        <div className="bg-primary-700 w-[2px] h-[44px]"></div>
        <div className="flex flex-row items-top w-full">
          <div className="market-flow-line4 min-w-[15px]"></div>
          <div className="triangle-market mr-[4px]"></div>
          <h3 className="text-center text-primary-700 font-semibold mt-[-16px] !text-[30px]">
            &#126;$30 billion TAM
          </h3>
        </div>
      </div>

      {/* arrow and tam */}

      <div className="divider32"></div>
      <div className="hidden mr-[-4px]">
        <div className="market-arrow-body"></div>
        <div className="triangle-market2"></div>
      </div>

      <div className="divider16"></div>
    </div>
  );
}

export default MarketSize;
