import SlideTemplate from "../SlideTemplate";

function Team () {
    return (
      <div className="inner-slide-content">
        <div>
          <SlideTemplate title1="Team" title2={[]} sections={[]} />
          <div className="flex flex-row">
            <div className="inline float-left">
              <img className="min-w-[32px] max-w-[32px] mt-[4px] mr-[8px]" src="/hand-wave-solid.svg" width="100%" height="auto" alt="hand wave"/>
            </div>
            <h2>Nice to meet you</h2>
          </div> 
          <div className="flex flex-row flex-wrap gap-[32px] content-center mobile-col">

            <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Adam.png" width="100%" height="auto" alt="Adam Kallaus"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Adam Kallaus</p>
                  <p className="text-left text-[22px] leading-[28px] font-normal text-primary-700 mobile-text-center">CEO</p>
                  <p className="text-left text-[22px] leading-[28px] font-light text-gray-800 mobile-text-center"><a href="mailto: adam@flippr.ai?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">adam@flippr.ai</a></p>
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">
                  <li className="mb-[8px]"><span className="relative left-[8px]">Previously a Director at strategic advisory firm Perella Weinberg Partners &#40;PWP&#41;</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Advised on 10+ M&#38;A transactions worth over $30 billion in aggregate value &#40;read: Adam used to make slide decks for a living&#41;</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Once emceed a stand-up comedy show in New York City</span></li>
                </ul>
              </div>
            </div>

            <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Tyler.png" width="100%" height="auto" alt="Tyler Bobella"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Tyler Bobella</p>
                  <p className="text-left text-[22px] leading-[28px] font-normal text-primary-700 mobile-text-center">COO</p>
                  <p className="text-left text-[22px] leading-[28px] font-light text-gray-800 mobile-text-center"><a href="mailto: adam@flippr.ai;tyler@flippr.ai;chester@flippr.ai;will@flippr.ai?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">tyler@flippr.ai</a></p>
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">                  
                  <li className="mb-[8px]"><span className="relative left-[8px]">Director of Engineering at LogicGate, an enterprise GRC SaaS company &#40;starts full-time at Flippr April 26&#41;</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Tyler was the first non-founder hire at LogicGate, where he helped build the MVP and grew the engineering org from 2 to 60+ people</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Used to play national competitive paintball</span></li>
                </ul>
              </div>
            </div>

            <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Chester.png" width="100%" height="auto" alt="Chester de Wolfe"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Chester de Wolfe</p>
                  <p className="text-left text-[22px] leading-[28px] font-normal text-primary-700 mobile-text-center">CTO</p>
                  <p className="text-left text-[22px] leading-[28px] font-light text-gray-800 mobile-text-center"><a href="mailto: adam@flippr.ai;tyler@flippr.ai;chester@flippr.ai;will@flippr.ai?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">chester@flippr.ai</a></p>
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">
                  <li className="mb-[8px]"><span className="relative left-[8px]">Previously a Software Engineer on the AI team at Capital One</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Considered pro soccer instead of college</span></li>
                </ul>
              </div>
            </div>
              
            <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Will.png" width="100%" height="auto" alt="Will Burke"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Will Burke</p>
                  <p className="text-left text-[22px] leading-[28px] font-normal text-primary-700 mobile-text-center">Founding Engineer</p>
                  <p className="text-left text-[22px] leading-[28px] font-light text-gray-800 mobile-text-center"><a href="mailto: adam@flippr.ai;tyler@flippr.ai;chester@flippr.ai;will@flippr.ai?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">will@flippr.ai</a></p>                 
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">
                  <li className="mb-[8px]"><span className="relative left-[8px]">Previously a Software Engineer at Mission Critical Partners and Goldman Sachs</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Has run 7 marathons</span></li>
                </ul>
              </div>
            </div>

          </div> 
          <div className="divider40"></div> 
          <div className="divider24"></div>
          <h2>Origin story</h2>
          <div className="shadow-[2px_2px_3px_#98A2B3] bg-[#F2F2F2] rounded-[8px] px-[24px] py-[16px]">
            <p className="text-gray-900 text-[18px] leading-[30px]">
              In his previous role at PWP, an M&A advisory firm, Adam&#39;s job primarily entailed creating presentations for, and on behalf of, management teams and boards of directors at multi-billion dollar companies. Adam saw first-hand how painful and inefficient the process was for making slide decks in a team environment and quit his job to help develop a solution. M&A firms in the US spend ~$10 billion each year on resources dedicated to making slide decks and are desperate for a better solution. In his role as an investment banker, Adam also noticed other companies experiencing similar problems in roles like consulting, tax, accounting, strategy, business development, marketing, IR/PR, etc.
            </p>
            <div className="divider24"></div>
            <p className="text-gray-900 text-[18px] leading-[30px]">
              Adam, Chester, and Tyler met through mutual friends in 2021. Adam and Will have been friends since college. The team built a demo in H1 2022, then raised ~$400k in SAFEs before Chester and Will joined full-time in the fall of 2022. Tyler joined full-time in April 2023. 
            </p>
          </div>

          <div className="divider40"></div>
          <div className="divider24"></div>          
          <h2>Board of Directors</h2>
          <div className="flex flex-row flex-wrap gap-[32px] content-center mobile-col">

          <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Haeberle2.png" width="100%" height="auto" alt="David Haeberle"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">David Haeberle</p>
                  {/* <p className="text-left text-[18px] leading-[24px] font-light text-gray-800 mobile-text-center"><a href="mailto: david@haeberleinvestments.com?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">david@haeberleinvestments.com</a></p> */}
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">
                  <li className="mb-[8px]"><span className="relative left-[8px]">Clinical Professor of Finance at Indiana University, where David teaches Entrepreneurship and has been the recipient of numerous awards</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Previously Co-founder and CFO of Envisage Technologies, a SaaS company that sold to Norwest Venture Partners and, subsequently, Vector Solutions</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Flippr investor</span></li>
                </ul>
              </div>
            </div>

            <div className="bios-col">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Dylan2.png" width="100%" height="auto" alt="Dylan McDermott"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Dylan McDermott</p>
                  {/* <p className="text-left text-[18px] leading-[24px] font-light text-gray-800 mobile-text-center"><a href="mailto: domcdermott@gmail.com?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">domcdermott@gmail.com</a></p> */}
                </div>              
              </div>
              <div className="mt-[16px] w-full pr-[8px]">
                <ul className="list-disc list-outside pl-[24px] leading-[26px] text-[18px] font-normal text-gray-900">
                  <li className="mb-[8px]"><span className="relative left-[8px]">Investment Director at CVC Capital Partners</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Previously a tech investor at Warburg Pincus</span></li>
                  <li className="mb-[8px]"><span className="relative left-[8px]">Flippr investor</span></li>
                </ul>
              </div>
            </div>        
              
            <div className="bios-col mx-auto">
              <div className="bio-photo-row-wrap mobile-col">
                <div className="bio-photo-wrap">
                  <img className="min-w-[100px] ml-[-4px]" src="/Photo-Adam.png" width="100%" height="auto" alt="Adam Kallaus"/>
                </div>
                <div className="flex flex-col w-full justify-center ml-[16px]">
                  <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900 mobile-text-center">Adam Kallaus</p>
                  <p className="text-left text-[22px] leading-[28px] font-normal text-primary-700 mobile-text-center">CEO of Flippr</p>
                  {/* <p className="text-left text-[18px] leading-[24px] font-light text-gray-800 mobile-text-center"><a href="mailto: adam@flippr.ai?subject=Flippr Pitch" className="hover:underline hover:text-primary-700 pointer">adam@flippr.ai</a></p>                  */}
                </div>              
              </div>              
            </div>
          </div>
          
        </div>
        <div className="divider40"></div>
      </div>
    );
  }



export default Team