import SlideTemplate from "../SlideTemplate";
import { TractionTableData } from "../../data/traction-table";

function Traction() {
  const tableData = TractionTableData;
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="traction" title2={[]} sections={[]} />
      <h2>
        35 companies have requested access to Flippr.ai, which will be rolled out in summer 2023
      </h2>
      <div className="flex flex-col gap-[0px]">        
        <div className="callout-box items-center gap-[16px]">
          <div className="bg-[#f5f5f5] flex flex-row items-center justify-center rounded-[8px] shadow-[2px_2px_3px_#d0d5dd] min-w-[64px] w-[64px] h-[64px] text-primary-700 font-semibold text-[36px]">
            11
          </div>
          <div className="flex flex-col gap-[8px]">
            <p className="text-[20px] leading-[30px] font-medium text-gray-900">
              <span className="text-primary-700">Investment banks&nbsp;</span>
              </p>
            <p className="text-[18px] leading-[24px] font-normal text-gray-900">with an aggregate employee count of over 300k</p>
          </div>        
        </div>
        <div className="callout-box items-center gap-[16px]">
          <div className="bg-[#f5f5f5] flex flex-row items-center justify-center rounded-[8px] shadow-[2px_2px_3px_#d0d5dd] min-w-[64px] w-[64px] h-[64px] text-primary-700 font-semibold text-[36px]">
            10
          </div>
          <div className="flex flex-col gap-[8px]">
            <p className="text-[20px] leading-[30px] font-medium text-gray-900">
              <span className="text-primary-700">Private equity firms&nbsp;</span>
              </p>
            <p className="text-[18px] leading-[24px] font-normal text-gray-900">with over 700 portfolio companies in aggregate</p>
          </div>        
        </div>
        <div className="callout-box items-center gap-[16px]">
          <div className="bg-[#f5f5f5] flex flex-row items-center justify-center rounded-[8px] shadow-[2px_2px_3px_#d0d5dd] min-w-[64px] w-[64px] h-[64px] text-primary-700 font-semibold text-[36px]">
            10
          </div>
          <div className="flex flex-col gap-[8px]">
            <p className="text-[20px] leading-[30px] font-medium text-gray-900">
              <span className="text-primary-700">Corporates&nbsp;</span>
              </p>
            <p className="text-[18px] leading-[24px] font-normal text-gray-900">across consumer/retail, health care, industrials, and supply chain sectors requested access for management, corp dev/strategy, and other corporate roles</p>
          </div>        
        </div>
        <div className="callout-box items-center gap-[16px]">
          <div className="bg-[#f5f5f5] flex flex-row items-center justify-center rounded-[8px] shadow-[2px_2px_3px_#d0d5dd] min-w-[64px] w-[64px] h-[64px] text-primary-700 font-semibold text-[36px]">
            4
          </div>
          <div className="flex flex-col gap-[8px]">
            <p className="text-[20px] leading-[30px] font-medium text-gray-900">
              <span className="text-primary-700">Other firms&nbsp;</span>
              </p>
            <p className="text-[18px] leading-[24px] font-normal text-gray-900">across investor relations, consulting, and private wealth management</p>
          </div>        
        </div>
      </div>
      

      
      <div className="divider32"></div>
    </div>
  );
}

export default Traction;
