/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessModel from "./slides/BusinessModel";
import Competition from "./slides/Competition";
import MarketSize from "./slides/MarketSize";
import Problem from "./slides/Problem";
import Wedge from "./slides/Wedge";
import Solution from "./slides/Solution";
import Team from "./slides/Team";
import WhyNow from "./slides/WhyNow";
import Disclaimer from "./slides/Disclaimer";
import Fundraising from "./slides/Fundraising";
import Traction from "./slides/Traction";
import Cover from "./slides/Cover";
import FAQ from "./slides/FAQ";
import { useCallback, useEffect, useState } from "react";

function SlideContent({ currentSlide, setCurrentSlide }) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      console.log("swipe", isLeftSwipe ? "left" : "right");
      handleNext();
    }
    if (isRightSwipe) {
      console.log("swipe", isLeftSwipe ? "left" : "right");
      handlePrev();
    }
  };
  const getSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return <Cover />;
      case 1:
        return <Problem />;
      case 2:
        return <Solution />;
      case 3:
        return <Team />;
      case 4:
        return <WhyNow />;
      case 5:
        return <Competition />;
      case 6:
        return <Wedge />;
      case 7:
        return <MarketSize />;
      case 8:
        return <BusinessModel />;
      case 9:
        return <Traction />;
      case 10:
        return <Fundraising />;
      case 11:
        return <Disclaimer />;
      default:
        return <div>NA</div>;
    }
  };
  const handlePrev = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(11);
    }
  };
  const handleNext = () => {
    if (currentSlide < 11) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  const keyShortcut = useCallback((event) => {
    if (event.key === "ArrowLeft") {
      setCurrentSlide((currentSlide) =>
        currentSlide !== 0 ? currentSlide - 1 : 11
      );
    }
    if (event.key === "ArrowRight") {
      setCurrentSlide((currentSlide) =>
        currentSlide !== 11 ? currentSlide + 1 : 0
      );
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyShortcut);
    return () => {
      document.removeEventListener("keydown", keyShortcut);
    };
  }, [keyShortcut]);
  return (
    <div
      className={`flex-grow bg-[#ECECEC] ${
        currentSlide === 0
          ? "overflow-y-hidden h-screen"
          : "overflow-y-visible min-h-screen"
      }`}
      // onTouchStart={onTouchStart}
      // onTouchMove={onTouchMove}
      // onTouchEnd={onTouchEnd}
    >
      <div className="lg:pl-[296px] flex h-full">
        <button
          className="text-gray-500 sm:hover:bg-gray-100 sm:hover:text-gray-800 w-[40px] fixed h-screen"
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-7" />
        </button>
        <div className="flex-grow mt-10 lg:mt-0 px-12 xl:px-0">
          {getSlideContent()}
        </div>
        <button
          className={`w-[40px] fixed h-screen right-0 sm:hover:bg-gray-100 sm:hover:text-gray-800 ${
            currentSlide === 0 ? "text-gray-100 z-50" : "text-gray-500"
          }`}
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faChevronRight} className="h-7" />
        </button>
      </div>
    </div>
  );
}

export default SlideContent;
