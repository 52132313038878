function Cover() {
  return (
    <div className="h-fit sm:h-full inner-slide-content overflow-y-hidden ">
      <div className="pt-28 fixed mobile-cover-text flex flex-col gap-[24px] justify-center h-[60vh] ml-[5vw]">
        <div className="z-10 w-1/2 max-w-[200px] min-w-[120px]">
          <img
            src="/Flippr-Logo.png"
            // width="100%"
            className="100%"
            height="auto"
            alt="Flippr Logo"
          />
        </div>
        <div>
          <p className="mb-[8px] text-left text-[22px] leading-[28px] font-medium text-gray-900">
            Long-term Vision
          </p>
          <p className="text-left text-[20px] leading-[28px] font-light text-gray-800">
            Summer 2023
          </p>
        </div>
      </div>

      <div className="z-20 fixed h-[95vh] w-1/3 top-0 right-[10vw]">
        <div className="cover-image-wrap z-20 relative max-w-[450px] mt-[40vh]">
          <img
            className="cover-image-comp"
            src="/cover-image.png"
            width="100%"
            height="auto"
            alt="Decorative"
          />
        </div>
      </div>
      {/* <div className="absolute flex flex-row justify-center items-center slide-arrow h-full top-0 right-0 z-[60] w-[40px] text-gray-100">
        <FontAwesomeIcon icon={faChevronRight} className="h-7 my-[auto] !pb-[4px] mr-[-6px]" /> 
      </div>       */}
      <div className="vector-rhs" />
    </div>
  );
}

export default Cover;
