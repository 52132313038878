import SlideTemplate from "../SlideTemplate";
import {
  faSquareCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function chBorder1(color,margin) {
  document.getElementById('flyNum1').style.border = color;
  document.getElementById('flyNum1').style.margin = margin;
}
function chBorder2(color,margin) {
  document.getElementById('flyNum2').style.border = color;
  document.getElementById('flyNum2').style.margin = margin;
}
function chBorder3(color,margin) {
  document.getElementById('flyNum3').style.border = color;
  document.getElementById('flyNum3').style.margin = margin;
}
function chBorder4(color,margin) {
  document.getElementById('flyNum4').style.border = color;
  document.getElementById('flyNum4').style.margin = margin;
}

function BusinessModel() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="business model" title2={[]} sections={[]} />
      <section>        
        <h2>Interactive nature of Flippr drives viral user adoption and go-to-market flywheel</h2>
        <div className="mt-[16px] mobile-col flex flex-row items-center w-full">

          <div className="flywheel-wrap w-1/2 relative">            
            <div className="flywheel-image-wrap relative w-fit mx-auto">
              <img id="flywheel-image" src="/Flywheel.png" className="mb-[16px] m-auto min-w-[180px]" width="80%" height="auto" alt="Virtuous cycle adoption effect" />      
              <div className="fly-num1 transition-all absolute left-[38%] top-[20%]">
                <div id="flyNum1" className="fly-number">1</div>
              </div>
              <div className="fly-num2 transition-all absolute top-[35%] right-[26.5%]">
                <div id="flyNum2" className="fly-number">2</div>
              </div>
              <div className="fly-num4 transition-all absolute bottom-[36%] left-[27.5%]">
                <div id="flyNum4" className="fly-number">4</div>
              </div>
              <div className="fly-num3 transition-all absolute bottom-[22.5%] right-[37.5%]">
                <div id="flyNum3" className="fly-number">3</div>
              </div>
            </div>
          </div>
          
          <div className="fly-hover-parent w-1/2">
            <div className="fly-hover-parent flex flex-col justify-center gap-[32px]">
              <div className="fly-hover flex flex-row justify-left items-center gap-[16px] bg-[#F2F2F2] shadow-[2px_2px_3px_#98A2B3] rounded-[8px] p-[8px]" onMouseEnter={()=> chBorder1('2px solid #4939DF','-2px')} onMouseLeave={()=> chBorder1('none','')}>              
                <div className="fly-number2">
                  <img src="/icon-send.png" width="120%" height="auto" alt="send icon" />
                </div>
                <p>User sends presentation...</p>
              </div>
              <div className="fly-hover flex flex-row justify-left gap-[16px] bg-[#F2F2F2] shadow-[2px_2px_3px_#98A2B3] rounded-[8px] p-[8px]" onMouseEnter={()=> chBorder2('2px solid #4939DF','-2px')} onMouseLeave={()=> chBorder2('none','')}>
                <div className="fly-number2">
                  <img src="/icon-view.png" width="120%" height="auto" alt="send icon" />
                </div>
                <p>…recipient creates free account to view the document…</p>
              </div>
              <div className="fly-hover flex flex-row justify-left gap-[16px] bg-[#F2F2F2] shadow-[2px_2px_3px_#98A2B3] rounded-[8px] p-[8px]" onMouseEnter={()=> chBorder3('2px solid #4939DF','2px')} onMouseLeave={()=> chBorder3('none','')}>
                <div className="fly-number2">
                  <img src="/icon-flippr.png" width="120%" height="auto" alt="send icon" />
                </div>
                <p>…recipient upgrades account to create presentations...</p>
              </div>
              <div className="fly-hover flex flex-row justify-left gap-[16px] bg-[#F2F2F2] shadow-[2px_2px_3px_#98A2B3] rounded-[8px] p-[8px]" onMouseEnter={()=> chBorder4('2px solid #4939DF','2px')} onMouseLeave={()=> chBorder4('none','')}>
                <div className="fly-number2">
                  <img src="/icon-users.png" width="120%" height="auto" alt="send icon" />
                </div>
                <p>…recipient recommends Flippr and further penetrates user reservoir…</p>
              </div>              
            </div>
          </div>                  
        </div>                                       
      </section>
      <div className="divider40"></div>
      <div className="divider40"></div>
      
      <section>
        <h2>Preliminary Flippr.<span className="text-primary-700">co</span> monthly pricing</h2>
        <div className="plans-container flex flex-row justify-center items-center flex-wrap gap-[32px]">

          {/* free plan */}
          <div className="plan-wrap py-[8px] px-[16px] flex flex-col justify-start items-center w-full bg-[#F9FAFB] shadow-[2px_2px_3px_#98A2B3] rounded-[8px]">
            <div className="plan-upper-wrap flex flex-col w-full justify-start items-center gap-[16px]">
              <div className="w-full">
                <p className="w-full text-primary-700 uppercase font-medium text-[18px] text-left">
                  Free Plan
                </p>
                <p className="w-full text-gray-800 font-normal text-[16px] text-left">
                  If you're just getting started
                </p>
              </div>
              <div className="flex flex-row justify-center items-start w-full">
                <p className="text-gray-500 font-bold leading-[18px] text-[18px]">$</p>
                <p className="text-gray-900 font-semibold leading-[32px] text-[48px]">0</p>
              </div>
              <p className="w-full uppercase text-gray-600 font-semibold text-[16px] text-center">
                Free forever
              </p>              
              <div className="h-[2px] mx-[8px] w-full bg-gray-300 rounded-[4px]"></div>
            </div>
            <div className="plan-lower-wrap mt-[8px] flex flex-col w-full justify-start items-center gap-[16px]">
              <ul className="check-list w-full">
                <li className="check-list-item">Create unlimited presentations</li>                
                <li className="check-list-item">View any presentations received from others</li>
                <li className="check-list-item">Share your first presentation for free</li>
                <li className="check-list-item x">Upgrade to share additional presentations</li>
              </ul>            
            </div>
          </div>

          {/* pro plan */}
          <div className="plan-wrap py-[8px] px-[16px] flex flex-col justify-start items-center w-full bg-[#F9FAFB] shadow-[2px_2px_3px_#98A2B3] rounded-[8px]">
            <div className="plan-upper-wrap flex flex-col w-full justify-start items-center gap-[16px]">
              <div className="w-full">
                <p className="w-full text-primary-700 uppercase font-medium text-[18px] text-left">
                  Pro Plan
                </p>
                <p className="w-full text-gray-800 font-normal text-[16px] text-left">
                  Best for individuals
                </p>
              </div>
              <div className="flex flex-row justify-center items-start w-full">
                <p className="text-gray-500 font-bold leading-[18px] text-[18px]">$</p>
                <p className="text-gray-900 font-semibold leading-[32px] text-[48px]">10</p>
              </div>
              <p className="w-full uppercase text-gray-600 font-semibold text-[16px] text-center">
                per month
              </p>              
              <div className="h-[2px] mx-[8px] w-full bg-gray-300 rounded-[4px]"></div>
            </div>
            <div className="plan-lower-wrap mt-[8px] flex flex-col w-full justify-start items-center gap-[16px]">
              <ul className="check-list w-full">
                <li className="check-list-item">Everything in free plan</li>
                <li className="check-list-item">Share unlimited presentations</li>
                <li className="check-list-item x">No collaboration features</li>
              </ul>            
            </div>
          </div>

            {/* Business plan */}
            <div className="plan-wrap py-[8px] px-[16px] flex flex-col justify-start items-center w-full bg-[#F9FAFB] shadow-[2px_2px_3px_#98A2B3] rounded-[8px]">
            <div className="plan-upper-wrap flex flex-col w-full justify-start items-center gap-[16px]">
              <div className="w-full">
                <p className="w-full text-primary-700 uppercase font-medium text-[18px] text-left">
                  Business Plan
                </p>
                <p className="w-full text-gray-800 font-normal text-[16px] text-left">
                  Best for small teams
                </p>
              </div>
              <div className="flex flex-row justify-center items-start w-full">
                <p className="text-gray-500 font-bold leading-[18px] text-[18px]">$</p>
                <p className="text-gray-900 font-semibold leading-[32px] text-[48px]">18</p>
              </div>
              <p className="w-full uppercase text-gray-600 font-semibold text-[16px] text-center">
                per month per user
              </p>              
              <div className="h-[2px] mx-[8px] w-full bg-gray-300 rounded-[4px]"></div>
            </div>
            <div className="plan-lower-wrap mt-[8px] flex flex-col w-full justify-start items-center gap-[16px]">
              <ul className="check-list w-full">
                <li className="check-list-item">Everything in pro plan</li>
                <li className="check-list-item">Administrative settings to manage your team</li>
                <li className="check-list-item">Collaboration features</li>
                <li className="check-list-item">SSO</li>
                <li className="check-list-item x">Limited data retention settings to meet regulatory requirements</li>

              </ul>            
            </div>
          </div>

          {/* enterprise plan */}
          <div className="plan-wrap py-[8px] px-[16px] flex flex-col justify-start items-center w-full bg-[#F9FAFB] shadow-[2px_2px_3px_#98A2B3] rounded-[8px]">
            <div className="plan-upper-wrap flex flex-col w-full justify-start items-center gap-[16px]">
              <div className="w-full">
                <p className="w-full text-primary-700 uppercase font-medium text-[18px] text-left">
                  Enterprise Plan
                </p>
                <p className="w-full text-gray-800 font-normal text-[16px] text-left">
                  Best for large organizations 
                </p>
              </div>
              <div className="flex flex-row justify-center items-start w-full">
                <p className="text-gray-500 font-bold leading-[18px] text-[18px]">$</p>
                <p className="text-gray-900 font-semibold leading-[32px] text-[48px]">25</p>
              </div>
              <p className="w-full uppercase text-gray-600 font-semibold text-[16px] text-center">
                per month per user
              </p>              
              <div className="h-[2px] mx-[8px] w-full bg-gray-300 rounded-[4px]"></div>
            </div>
            <div className="plan-lower-wrap mt-[8px] flex flex-col w-full justify-start items-center gap-[16px]">
              <ul className="check-list w-full">
                <li className="check-list-item">Everything in business plan</li>
                <li className="check-list-item">Audit log</li>
                <li className="check-list-item">Granular admin roles and data retention settings</li>
                <li className="check-list-item">Advanced security controls</li>
              </ul>            
            </div>
          </div>
          <h3 className="text-[22px] text-primary-700 text-center cursor-pointer hover:underline">
            <a href="https://www.flippr.ai/plans" target="_blank" rel="noreferrer">Click here to see Flippr.ai pricing</a>
          </h3>        
        </div>
      </section>
      <div className="divider40"></div>
      <div className="divider16"></div>      
    </div>
  );
}

export default BusinessModel;
