import SlideTemplate from "../SlideTemplate";

function fundraising() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="Timeline" title2={[]} sections={[]} />
      {/* <h2>
        Raising {" "}
        <span className="text-primary-700 font-semibold">$1.5M</span>
        {" "}
        to support commercialization of Flippr.ai
      </h2> */}
      <section>
        <div className="flex flex-row w-full">
          <div className="timeline-arrow-wrap">
            <div className="timeline-arrow-base"></div>
            <div className="triangle-timeline-wrap sticky bg-[#ECECEC] bottom-[-0px]">
              <div className="triangle-timeline"></div>
            </div>
          </div>
          <div className="w-full">
            <div className="ml-[-40px] flex flex-row w-full">
              <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-primary-700 border-primary-700 border-[0px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">
                  H1 2022:
                </span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">
                    $60k founder cash contribution for common stock
                  </li>
                </ul>
              </p>
            </div>            
            <div className="ml-[-40px] mt-[40px] flex flex-row w-full">
              <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-primary-700 border-primary-700 border-[0px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">
                  Summer 2022:
                </span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">
                    Flippr.ai demo created and shown to 100+ people
                  </li>
                  <li className="mt-[6px]">$395k of SAFEs raised</li>
                  <li className="mt-[6px]">&#126;35 companies request access to Flippr.ai beta</li>
                </ul>
              </p>
            </div>
            <div className="ml-[-40px] mt-[40px] flex flex-row w-full">
              <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-primary-700 border-primary-700 border-[0px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">Fall 2022:</span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">
                    Chester and Will join Flippr full-time
                  </li>
                  <li className="mt-[6px]">
                    Flippr now has 3 FTEs, including Adam
                  </li>
                </ul>
              </p>
            </div>                               
            
            <div className="ml-[-40px] mt-[40px] flex flex-row w-full">
            <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-primary-700 border-primary-700 border-[0px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">
                  April 2023:
                </span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">Free/pro plan beta released to investors; still working on SOC 2 and enterprise plan</li>             
                  <li className="mt-[6px]">Tyler joins Flippr full-time on April 26</li>
                </ul>
              </p>
            </div>
            
            <div className="ml-[-40px] mt-[40px] flex flex-row w-full">
              <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-[#ECECEC] border-primary-700 border-[2px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">August 2023:</span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">SOC 2 audit complete</li>
                  <li className="mt-[6px]">Flippr.ai launches</li>
                </ul>
              </p>
            </div>         
            
            <div className="ml-[-40px] mt-[40px] flex flex-row w-full">
              <div className="ml-[8px] h-[24px] w-[24px] min-w-[24px] bg-[#ECECEC] border-primary-700 border-[2px] rounded-[100%]"></div>
              <div className="timeline-h-line"></div>
              <p className="timeline-text">
                <span className="text-primary-700 font-medium">June 2024:</span>
                <br></br>
                <ul className="list-outside list-disc ml-[16px] min-w-[144px]">
                  <li className="mt-[6px]">
                    Flippr currently has enough cash to last until June 2024, before taking into account any revenue or additional capital
                  </li>                  
                </ul>
              </p>
            </div>
            

          </div>
        </div>
      </section>
      <div className="divider16"></div>
    </div>
  );
}

export default fundraising;
