export const competitionTableData = [
  {
    id: 0,
    name: "Traditional",
    data: [
      {
        item_id: 0,
        logo: "/Logos/Logo-PPT.png",
        imgAlt: "PowerPoint",
        design: true,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Powerpoint</span>
            <span>
              ~500M users. PowerPoint is easy to use yet flexible &#40;i.e. it
              offers a robust suite of design features and tools&#41;, making it
              the appropriate choice for most enterprise presentation needs
              &#40;for now&#41;.
            </span>
          </div>
        ),
      },
      {
        item_id: 1,
        logo: "/Logos/Logo-G-Workspace.png",
        imgAlt: "google",
        design: true,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Google</span>
            <span>~8M paying subscribers. Similar to PowerPoint.</span>
          </div>
        ),
      },
      {
        item_id: 2,
        logo: "/Logos/Logo-P-Pikto.png",
        imgAlt: "PiktoChart",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Piktochart</span>
            <span>~11M users. ~300k unique website visitors per week. ~48 employees.</span>
            <span>Canva meets Google Slides.</span>
          </div>
        ),
      },
      {
        item_id: 3,
        logo: "/Logos/Logo-P-Venngage.png",
        size: "sm",
        imgAlt: "Venngage",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Venngage</span>
            <span>~63 employees.</span>
            <span>Canva meets Google Slides.</span>
          </div>
        ),
      },
    ],
  },
  {
    id: 1,
    name: "Alternate Format",
    nameAlt: "Immersive Format",
    data: [
      {
        item_id: 0,
        logo: "/Flippr-Logo.png",
        size: "md",
        imgAlt: "flippr",
        design: true,
        ease: true,
        immersive: true,
        border: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Flippr</span>
            <span>
              The first immersive presentation platform &#40;i.e. flexible page
              dimensions, nested components, enhanced page navigation, etc.&#41;
              offering flexible design tools similar to PowerPoint and Google
              Slides.
            </span>
          </div>
        ),
      },
      {
        item_id: 1,
        logo: "/Logos/Logo-P-Tome.png",
        imgAlt: "tome",
        size: "md",
        design: false,
        ease: true,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Tome</span>
            <span>Seed: $6M (Jan 2021)</span>
            <span>Total raised: $81M</span>
            <span>
              Resembles a slide deck with flexible page dimensions, but with
              limited design flexibilitiy.
            </span>
          </div>
        ),
      },
      {
        item_id: 2,
        logo: "/Logos/Logo-P-Gamma.png",
        size: "md",
        imgAlt: "gamma",
        design: false,
        ease: true,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Gamma</span>
            <span>Seed: $7M (Oct 2021)</span>
            <span>Total Raised: $7M</span>
            <span>
              Digital, card based platform intended to replace the slidedeck.
            </span>
          </div>
        ),
      },
      {
        item_id: 3,
        logo: "/Logos/Logo-P-Foleon.png",
        size: "md",
        imgAlt: "Foleon",
        design: false,
        ease: false,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Foleon</span>
            <span>Seed: &#126;$1M &#40;2015&#41;</span>
            <span>Total Raised: $16M</span>
            <span>
              Interactive presentation platform targeted to B2B content marketers.
            </span>
          </div>
        ),
      },
      {
        item_id: 4,
        logo: "/Logos/Logo-P-Digideck.png",
        size: "md",
        imgAlt: "Digideck",
        design: false,
        ease: false,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Digideck</span>
            <span>Seed: $3M &#40;2017&#41;</span>
            <span>Total Raised: $8M</span>
            <span>
              Interactive presentation platform targeted to B2B content marketers. Digideck designers set your slides up for you.
            </span>
          </div>
        ),
      },
      {
        item_id: 5,
        logo: "/Logos/Logo-P-Prezi.png",
        imgAlt: "prezi",
        design: false,
        ease: false,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Prezi</span>
            <span>Seed: 2009</span>
            <span>Latest Val: &#126;$300M &#40;2014&#41;</span>
            <span>
              Simplified, interactive presentation editor with cool transitions.
              Also offers video overlays.
            </span>
          </div>
        ),
      },    
      {
        item_id: 6,
        logo: "/Logos/Logo-Notion.png",
        size: "lg",
        imgAlt: "notion",
        design: false,
        ease: true,
        immersive: true,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Notion</span>
            <span>
              Representative of broader sector of all-in-one workspace players,
              which can be used as presentation alternatives.
            </span>
          </div>
        ),
      },
    ],
  },
  {
    id: 2,
    name: "Broader Design",
    data: [
      {
        item_id: 0,
        logo: "/Logos/Logo-Figma.png",
        imgAlt: "figma",
        design: true,
        ease: false,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Figma</span>
            <span>
              Acquisition by adobe for $20B announced Sep 2022 &#40;being challenged by FTC&#41;. Robust design
              tool with modernized UI.
            </span>
          </div>
        ),
      },
      {
        item_id: 1,
        logo: "/Logos/Logo-Canva.png",
        size: "md",
        imgAlt: "canva",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Canva</span>
            <span>&#126;$25B valuation.</span>
            <span>
              Canva makes design easy and accessible, but in doing so offers
              limited functionality and design flexibility. Most of its business
              is social media. Adobe's VP of IR says publicly that Canva is
              “where beginners get started before they come to Adobe.”
            </span>
          </div>
        ),
      },
    ],
  },
  {
    id: 2,
    name: "Pre-structured slides",
    data: [
      {
        item_id: 0,
        logo: "/Logos/Logo-P-Pitch.png",
        size: "md",
        imgAlt: "pitch",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Pitch</span>
            <span>Seed: $4M &#40;2018&#41;</span>
            <span>Latest Val: &#126;$530M &#40;May 2021&#41;</span>
            <span>Total Raised: &#126;$138M</span>
          </div>
        ),
      },
      {
        item_id: 1,
        logo: "/Logos/Logo-P-Beaut.png",
        size: "sm",
        imgAlt: "beautiful.ai",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Beautiful.ai</span>
            <span>Seed: $5M &#40;2016&#41;</span>
            <span>Latest Val: $36M &#40;2018&#41;</span>
            <span>Total Raised: $16M</span>
          </div>
        ),
      },
      {
        item_id: 2,
        logo: "/Logos/Logo-P-Visme.png",
        imgAlt: "visme",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Visme</span>
            <span>Mostly bootstrapped. &#126;80 employees.</span>
          </div>
        ),
      },
      {
        item_id: 3,
        logo: "/Logos/Logo-P-Slidebean.png",
        size: "lg",
        imgAlt: "slidebean",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Slidebean</span>
            <span>Seed: &#126;$1M &#40;2016&#41;</span>
            <span>Mostly bootstrapped. &#126;40 employees.</span>
          </div>
        ),
      },
    ],
  },
  {
    id: 3,
    name: "Plugins/features",
    data: [
      {
        item_id: 0,
        logo: "/Logos/Logo-P-ThinkCell.png",
        imgAlt: "Think-Cell",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Think-Cell</span>
            <span>
              PowerPoint/excel plug-in for making charts. Acquired by Cinven in
              2021, when think-cell had &#126;$100M of revenue.
            </span>
          </div>
        ),
      },
      {
        item_id: 1,
        logo: "/Logos/Logo-P-Upslide.png",
        size: "lg",
        imgAlt: "UpSlide",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">UpSlide</span>
            <span>
              PowerPoint productivity plug-in with &#126;$20M revenue. Similar
              plugins are offered by FactSet, CapIQ, Macabacus and others.
            </span>
          </div>
        ),
      },
      {
        item_id: 2,
        logo: "/Logos/Logo-P-Templafy.png",
        size: "lg",
        imgAlt: "Templafy",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Templafy</span>
            <span>Seed: $2.5M &#40;2014&#41;</span>
            <span>Total Raised: $154M</span>
            <span>
              Template library for presentation and word documents plus
              automated document creation features.
            </span>
          </div>
        ),
      },
      {
        item_id: 3,
        logo: "/Logos/Logo-P-Prezent.png",
        imgAlt: "Prezent.ai",
        size: "lg",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Prezent.ai</span>
            <span>Seed: $4M &#40;2021&#41;</span>
            <span>Latest Val: $125M &#40;May 2022&#41;</span>
            <span>Total Raised: $24M</span>
            <span>
              Self-serve corporate templates with outsourced services.
            </span>
          </div>
        ),
      },
      {
        item_id: 4,
        logo: "/Logos/Logo-P-Proposify.png",
        size: "lg",
        imgAlt: "Proposify",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Proposify</span>
            <span>Seed: &#126;$1M &#40;2014&#41;</span>
            <span>Total raised: &#126;$16M</span>
            <span>
              Like a CLM, but for presentation-like proposals and quotes as well
              as contracts.
            </span>
          </div>
        ),
      },      
      {
        item_id: 5,
        logo: "/Logos/Logo-P-Matik.png",
        imgAlt: "Matik",
        design: false,
        ease: true,
        immersive: false,
        hoverText: (
          <div className="flex flex-col p-2 gap-2">
            <span className="underline">Matik</span>
            <span>Seed: $3M &#40;Oct 2019&#41;</span>
            <span>Latest Val: $100M &#40;Sep 2021&#41;</span>
            <span>Total Raised: $23M</span>
            <span>
              Software for customizing Google Slides or PowerPoints such that
              numbers automatically update.
            </span>
          </div>
        ),
      },    
    ],
  },
];
