import { useState } from "react";
import axios from "axios";
import { useStore } from "../../store/store";

function StepTwo() {
  const updateEmail = useStore((state) => state.updateEmail);
  const [emailInput, setEmailInput] = useState("");
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  function checkEmailFormat(emailString) {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailString);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    if (checkEmailFormat(emailInput)) {
      const body = { email: emailInput };
      const options = {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };
      const logUser = async () => {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/user`,
          body,
          options
        );
        console.log(res);
        if (res.status === 200) {
          updateEmail(emailInput);
        } else {
          setConnectionError(true);
        }
      };
      logUser();
    } else {
      setInvalidEmailFormat(true);
    }
  };
  return (
    <div className="flex flex-col px-4 py-10 gap-2">
      <div className="flex flex-col justify-center gap-2">
        <label for="email" className="text-gray-50 text-lg">
          Enter Email:
        </label>
        <input
          id="email"
          aria-label="email"
          type="email"
          onChange={(e) => setEmailInput(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-grow text-lg py-2 rounded-md border-[3px] border-transparent hover:border-primary-700 focus:border-gray-300 bg-gray-50"
        />
      </div>
      <span
        className={`${
          invalidEmailFormat || connectionError ? "block" : "invisible"
        } text-red-400 self-end font-semibold tracking-[0.02em]`}
      >
        {invalidEmailFormat
          ? "Invalid Email Format"
          : "Error occurred, please try again"}
      </span>
      <span className="text-white italic">
        By clicking submit, I confirm I am an accredited investor
      </span>

      <button
        onClick={handleSubmit}
        className="bg-primary-700 hover:bg-primary-800 text-white w-full self-center px-4 py-2 text-lg font-semibold tracking-wide rounded-md"
      >
        Submit
      </button>
    </div>
  );
}

export default StepTwo;
