import useDeviceSize from "../../../hooks/useDeviceSize";
import { competitionTableData } from "../../../data/competition-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown, faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "flowbite-react";

function CompTable() {
  const [width] = useDeviceSize();
  const tableWidth =
    width > 1200 ? width - 400 : width > 1023 ? width - 400 : width - 100;
  return (
    <div>
      <div className="z-20 sticky top-10 lg:top-0">
        <div
          style={{
            width: tableWidth,
          }}
          className="w-fit max-w-[900px] flex justify-between border-b-2 py-2 bg-[#ECECEC] border-gray-700 text-base sm:text-lg font-semibold"
        >
          <div className="w-full flex">
            <div className="text-left self-end">
              <span className="">Company {width > 640 && "& Category"}</span>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="text-center self-end">Design Flexibility</div>
          </div>
          <div className="w-full flex justify-center">
            <div className="text-center self-end">Ease of Use</div>
          </div>
          <div className="w-full hidden lg:flex justify-center">
            <div className="text-center self-end">Immersive</div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {competitionTableData.map((section) => (
          <div key={section.id} className="py-4 flex flex-col gap-2">
            <div className="px-0 pb-2">
              <span className="underline font-semibold">
                {section.nameAlt && width < 1024
                  ? section.nameAlt
                  : section.name}
              </span>
            </div>
            {section.data.map((row) => (
              <>
                {width >= 640 ? (
                  <Tooltip
                    content={row.hoverText}
                    placement="bottom"
                    className="hidden sm:block w-64 sm:w-96 z-50 relative"
                    animation={false}
                    trigger={"hover" || "trigger"}
                  >
                    <div
                      key={row.item_id}
                      style={{
                        width: tableWidth,
                      }}
                      className={`w-full max-w-[900px] grid grid-cols-3 lg:grid-cols-4 py-2 h-14 px-2 rounded-md hover:opacity-80 hover:cursor-help ${
                        !(row.item_id % 2) && "bg-white "
                      } ${row.border && "border-2 border-primary-700"} ${
                        row.border ? "h-[52px]" : "h-12"
                      }`}
                    >
                      <img
                        src={row.logo}
                        className={`self-center ${
                          row.size === "sm"
                            ? "h-4"
                            : row.size === "md"
                            ? "h-6"
                            : row.size === "lg"
                            ? "h-7"
                            : "h-8"
                        }`}
                        alt="Notion logo"
                      />

                      <div className="flex justify-center">
                        <FontAwesomeIcon
                          icon={row.design ? faFaceSmile : faFaceFrown}
                          className={`self-center h-8 ${
                            row.design ? "text-[#12b76a]" : "text-[#d92d20]"
                          }`}
                        />
                      </div>
                      <div className="flex justify-center">
                        <FontAwesomeIcon
                          icon={row.ease ? faFaceSmile : faFaceFrown}
                          className={`self-center h-8 ${
                            row.ease ? "text-[#12b76a]" : "text-[#d92d20]"
                          }`}
                        />
                      </div>
                      <div className="hidden lg:flex justify-center">
                        <FontAwesomeIcon
                          icon={row.immersive ? faFaceSmile : faFaceFrown}
                          className={`self-center h-8 ${
                            row.immersive ? "text-[#12b76a]" : "text-[#d92d20]"
                          }`}
                        />
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={row.item_id}
                    style={{
                      width: tableWidth,
                    }}
                    className={`w-full max-w-[900px] grid grid-cols-3 lg:grid-cols-4 py-2 h-14 px-2 rounded-md hover:opacity-80 hover:cursor-help ${
                      !(row.item_id % 2) && "bg-white "
                    } ${row.border && "border-2 border-primary-700"} ${
                      row.border ? "h-[52px]" : "h-12"
                    }`}
                  >
                    <img
                      src={row.logo}
                      className={`self-center ${
                        row.size === "sm"
                          ? "h-4"
                          : row.size === "md"
                          ? "h-6"
                          : row.size === "lg"
                          ? "h-7"
                          : "h-8"
                      }`}
                      alt="Notion logo"
                    />

                    <div className="flex justify-center">
                      <FontAwesomeIcon
                        icon={row.design ? faFaceSmile : faFaceFrown}
                        className={`self-center h-8 ${
                          row.design ? "text-[#12b76a]" : "text-[#d92d20]"
                        }`}
                      />
                    </div>
                    <div className="flex justify-center">
                      <FontAwesomeIcon
                        icon={row.ease ? faFaceSmile : faFaceFrown}
                        className={`self-center h-8 ${
                          row.ease ? "text-[#12b76a]" : "text-[#d92d20]"
                        }`}
                      />
                    </div>
                    <div className="hidden lg:flex justify-center">
                      <FontAwesomeIcon
                        icon={row.immersive ? faFaceSmile : faFaceFrown}
                        className={`self-center h-8 ${
                          row.immersive ? "text-[#12b76a]" : "text-[#d92d20]"
                        }`}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompTable;
