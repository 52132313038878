import SlideTemplate from "../SlideTemplate";

function Problem() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate
        title1="problem"
        title2={[ ]}
        sections={[ ]}
      />
      <h2>Slide decks use printable page dimensions, even when they are read digitally</h2>
      <div className="photo-1 flex justify-center max-w-[400px] mx-auto">
        <img
          src="/Square-peg-round-hole.png"
          width="100%"
          height="auto"
          alt="Square peg being forced into a round hole"
        />
      </div>
      <h2>They&#39;re also a pain to make...</h2>
      <div className="callout-box">
        <div className="circle-number-wrap mobile-hidden">          
          <p className="circle-number">1</p>
        </div>
        <div className="photo-and-image">        
          <div className="photo-2 flex justify-center max-w-[150px]">
            <img
              src="/Slide-Markup.png"
              width="100%"
              height="auto"
              alt="Convoluted layout of a PowerPoint slide"
            />        
          </div>
          <p className="paragraph-big text-gray-900">Small changes cause significant layout issues</p>      
        </div>
      </div>
      <div className="callout-box">
        <div className="circle-number-wrap mobile-hidden">          
          <p className="circle-number">2</p>
        </div>
        <div className="photo-and-image">        
          <div className="photo-2 flex justify-center max-w-[150px]">
            <img
              src="/Investigation-Board.png"
              width="100%"
              height="auto"
              alt="Police investigation board, but with various data sources"
            />        
          </div>
          <p className="paragraph-big text-gray-900">Data sources aren&#39;t integrated, meaning updating numbers is a manual and error-prone process</p>
        </div>
      </div>
      <div className="callout-box">
        <div className="circle-number-wrap mobile-hidden">          
          <p className="circle-number">3</p>
        </div>
        <div className="photo-and-image">        
          <div className="photo-2 flex justify-center max-w-[150px]">
            <img
              src="/PDF-Collab.png"
              width="100%"
              height="auto"
              alt="Painful review and iteration of a PowerPoint slide"
            />        
          </div>
          <p className="paragraph-big text-gray-900">Inadequate collaboration tools &#40;tracking changes, version control, markups, etc.&#41;</p>      
        </div>            
      </div>
      <div className="divider16"></div>      
    </div>
  );
}

export default Problem;
