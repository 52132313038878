import SlideTemplate from "../SlideTemplate";
import CompTable from "./sub-components/CompTable";

function Competition() {
  return (
    <div className="inner-slide-content">
      <SlideTemplate title1="competition" title2={[]} sections={[]} />
      <h2>
        Flippr.co will be the first immersive presentation platform built for
        enterprise customers
      </h2>
      <CompTable />
      <div className="relative"></div>
      <div className="divider40"></div>
      <div className="divider32"></div>
      <div className="divider40 hidden immersive-show-mobile"></div>
    </div>
  );
}

export default Competition;
