import { useState } from "react";
import { useStore } from "../../store/store";

function StepOne() {
  const updatePasswordAuthenticated = useStore(
    (state) => state.updatePasswordAuthenticated
  );
  const [passwordInput, setPasswordInput] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    if (passwordInput === process.env.REACT_APP_AUTH_PASSWORD) {
      updatePasswordAuthenticated(true);
    } else {
      setInvalidPassword(true);
    }
  };
  return (
    <div className="flex flex-col px-4 py-10 gap-2">
      <div className="flex flex-col justify-center gap-2">
        <label for="password" className="text-gray-50 text-lg ">
          Enter Password:
        </label>
        <input
          aria-label="password"
          type="password"
          id="password"
          onChange={(e) => setPasswordInput(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-grow text-lg py-2 rounded-md border-[3px] border-transparent hover:border-primary-700 focus:border-gray-300 bg-gray-50"
        />
      </div>
      <span
        className={`${
          invalidPassword ? "block" : "invisible"
        } text-red-400 self-end font-semibold tracking-[0.02em]`}
      >
        Incorrect Password
      </span>
      <button
        onClick={handleSubmit}
        className="bg-primary-700 hover:bg-primary-800 text-white w-full self-center px-4 py-2 text-lg font-semibold tracking-wide rounded-md"
      >
        Submit
      </button>
    </div>
  );
}

export default StepOne;
//bg-[#475467]
